.social-icons svg {
    transition: transform 0.3s ease;
  }
  
  .social-icons svg:hover {
    transform: scale(1.2); /* Increase the size on hover */
  }







  