/* Navbar.css */

.mobile-menu {
  position: fixed;
  top: 0;
  /* bottom: 0; */
  right: -100%;
  /* width: 30%; */
  height: 100vh;
  background: rgba(0, 0, 0, 0.9);
  transition: right 0.5s ease;
  z-index: 2000;
}

.mobile-menu.open {
  right: 0;
}


