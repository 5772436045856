.plain:hover p{
    color: white;
}

.dark:hover p{
    color: #1c1917;
}

.mild1:hover p{
    color: #1c1917;
}

.mild2:hover p{
    color: white;
}