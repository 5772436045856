/* Add these styles to your CSS or SCSS file */

.relative {
    position: relative;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background: linear-gradient(to right, rgba(0, 0, 0, 0.7), transparent); */
    background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
    /* background: rgba(
        0,
        0,
        0,
        0.5
      ); */
    opacity: 0;
    transition: opacity 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    transform: translateX(-10%);
    transition: opacity 0.3s, transform 0.5s;
    /* transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out; */
  }
  

  

  /* Hover effect - Show overlay on hover */
.relative:hover .overlay {
    opacity: 1;
    transform: translateX(0);
  }