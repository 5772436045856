.nav-icon-5 {
  width: 30px;
  height: 24px;
  position: relative;
  cursor: pointer;
  display: inline-block;
  margin-top: 30px;
  margin-right: 30px;
  margin-left: auto;
}

.nav-icon-5::before {
  content: '';
  position: absolute;
  width: 50px;
  height: 50px; /* Adjust height for a circular background */
  background-color: #06549F; /* Set your desired background color */
  border-radius: 50%; /* Create a circular shape */
  top: -12px; /* Adjust the top position for centering */
  left: -10px; /* Adjust the left position for centering */
  z-index: -1; /* Move the background behind the icon */
}

.nav-icon-5 span {
  /* position: absolute;
  border-radius: 1px;
  transition: 0.3s cubic-bezier(0.8, 0.5, 0.2, 1.4); */
  width: 100%;
  height: 2px;
  position: absolute;
  /* transition-duration: 500ms;  */
}

.nav-icon-5 span:nth-child(1) {
  /* top: 0px;
  left: 0px; */
  top: 11px;
  background-color: white;
  
}

.nav-icon-5 span:nth-child(2) {
  /* top: 11px;
  left: 0px; */
  opacity: 0;
}

.nav-icon-5 span:nth-child(3) {
  /* bottom: 0px;
  left: 0px; */
  top: 11px;
  background-color: white;
  
}

.nav-icon-5.open span:nth-child(1) {
  background-color: white;
  transform: rotate(45deg);
  top: 11px;
}

.nav-icon-5.open span:nth-child(2) {
  opacity: 0;
}

.nav-icon-5.open span:nth-child(3) {
  background-color: white;
  transform: rotate(-45deg);
  top: 11px;
}

.margin-left-auto{
  margin-left: auto;
}

