/* Add these styles to your CSS or SCSS file */

.relative-design {
  position: relative;
}

.overlay-design {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #0b5aa8;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  opacity: 0;
  transition: opacity 0.3s;
  transform: translateX(-10%);
  transition: opacity 0.3s, transform 0.5s;
  /* transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out; */
}

/* Hover effect - Show overlay on hover */
.relative-design:hover .overlay-design {
  opacity: 1;
  transform: translateX(0);
}

.design-1:hover p{
  color: #0b5aa8;
}

.design-2:hover p{
  color: #0b5aa8;
}

.design-3:hover p{
  color: #0b5aa8;
}
